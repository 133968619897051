import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import pdfToText from "react-pdftotext";

function App() {
  // https://www.codefrontend.com/file-upload-reactjs/
  const [file, setFile] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  function handleTipUs() {
    console.log('TIP US!!');
  }

  function extractText(file) {
    pdfToText(file)
      .then((text) => console.log(text))
      .catch((error) => console.error("Failed to extract text from pdf"));
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];

      // TODO update if we can send multiple
      setFile(e.target.files[0]);
      extractText(file)
    }
  };

  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return <Button variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }} onClick={() => loginWithRedirect()}>Log In</Button>;
  };

  const LogoutButton = () => {
    const { logout } = useAuth0();
    return (
      <Button variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
        Log Out
      </Button>
    );
  };

  const Profile = () => {
    const { user, isAuthenticated } = useAuth0();
    setIsLoggedIn(isAuthenticated);
    if (!isAuthenticated) {
      return (
        <Stack direction="row" spacing={3}>
          <Avatar alt="User avatar" sx={{ bgcolor: '#0A2239' }} />
          <LoginButton />
        </Stack>
      )
    }

    return (
      isAuthenticated && (
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={3}>
            <Avatar alt={user.name} src={user.picture} />
            <p style={{ color: '#0A2239' }}>{user.email}</p>
          </Stack>
          <LogoutButton />
        </Stack>
      )
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ right: 20, position: 'absolute', top: 20 }}>
          <Profile />
        </div>

        <h2 style={{ color: '#0A2239' }}>Convert a PDF into a Google Doc or Plain Text</h2>

        <Stack direction="row" spacing={3}>
          <h3 style={{ color: '#0A2239' }}>Then store everything in your Google Drive</h3>
          <Button variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }} onClick={handleTipUs}>Tip Us!</Button>
        </Stack>

        <p style={{ color: '#0A2239' }}>We value your privacy, so all your documents stay with you.<br />You supply the storage, we supply the transformed documents. We do not save your data.*</p>
        <p style={{ color: '#0A2239' }}>Step 1: Sign in with your Google account above.</p>
        <p style={{ color: '#0A2239' }}>Step 2: Upload your PDF document below</p>
        <Stack direction="row" spacing={3}>
          {/* https://frontendshape.com/post/how-to-use-file-upload-in-react-mui-5 */}
          <Button disabled={!isLoggedIn} variant="contained" component="label"
            sx={{ backgroundColor: '#0A2239', margin: 10 }}>
            Upload PDF
            {/* https://stackoverflow.com/questions/1548194/how-to-get-the-htmls-input-element-of-file-type-to-only-accept-pdf-files */}
            <input onChange={handleFileChange} hidden accept="image/*" multiple type="file" />
          </Button>
        </Stack>

        <p style={{ color: '#0A2239' }}>Step 3 - Select Google Doc or Plain Text below</p>

        <Stack direction="row" spacing={3}>
          <Button disabled={!isLoggedIn} variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }}>Google Doc</Button>
          <Button disabled={!isLoggedIn} variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }}>Plain Text</Button>
        </Stack>
        <p style={{ paddingTop: 80, color: '#0A2239', fontSize: 12, width: '70%' }}>*Seriously, we value our own privacy and we don't like drama: we don't save any of your information. We don't even have cookies or anything stored  in the browswer so you'll have to authenticate every time.</p>
      </header>
    </div >
  );
}

export default App;
